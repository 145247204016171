import * as React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import Layout from "../components/Layout";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <div css={NotfoundStyle}>
        <h2>존재하지 않는 페이지입니다.</h2>
        <Link to="/">홈으로 돌아가기</Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

const NotfoundStyle = css`
  height: 600px;
  width: 1024px;
  padding: 100px 50px;

  h2 {
    font-size: 32px;
    margin-bottom: 30px;
  }

  a {
    text-decoration: underline;
    font-size: 18px;
    color: #3751ff;
  }
`;
